import { enableScroll, stopScroll } from '../__utils/lockScroll';

const $ = jQuery.noConflict();

class LightboxGallery {
    constructor() {
        this.lightboxes = $('.lightbox-gallery__slider');
        this.lightboxOpen = $('.lightbox-gallery__single-thumb');
        this.lightboxClose = $('.lightbox-gallery__close');
    }
    init() {
        this.bindEvents();
    }
    bindEvents() {
        this.lightboxOpen.on('click', this.openLightbox);
        this.lightboxClose.on('click', this.closeLightbox);
    }
    openLightbox(e) {
        e.preventDefault();
        const slideNum = parseInt(this.hash.slice(1), 10);
        const lightboxBlock = $(this).closest('.block-gallery-lightbox');

        $('.lightbox-gallery__gallery-wrapper', lightboxBlock).addClass('active');
        $('.lightbox-gallery__slider', lightboxBlock).slick('slickGoTo', slideNum);
        stopScroll();
    }
    closeLightbox() {
        const lightboxBlock = $(this).closest('.block-gallery-lightbox');
        $('.lightbox-gallery__gallery-wrapper', lightboxBlock).removeClass('active');
        enableScroll();
    }
}
export default new LightboxGallery();
