const $ = jQuery.noConflict();
class Tables {
    constructor() {
        this.tables = $('table.tablepress');
    }
    init() {
        this.wrapTables();
        this.bindEvents();
        this.responsiveTableWidth();
    }
    bindEvents() {
        $(window).resize(() => this.toggleTablesShadow());
        $(window).resize(() => this.responsiveTableWidth());
    }
    wrapTables() {
        this.tables.wrap('<div class="table-wrapper"></div>');
    }
    toggleTablesShadow() {
        function shadowFunc() {
            const el = $(this);
            const wrapper = el.parent('.table-wrapper');
            if (el.find('tr').width() > el.width()) {
                wrapper.addClass('has-scroll');
            } else {
                wrapper.removeClass('has-scroll');
            }
        }
        this.tables.each(shadowFunc);
    }
    responsiveTableWidth() {
        function tableWidth() {
            const newWidth = $(window).width() - $(this).offset().left;
            if ($('.tablet-checker').is(':visible')) {
                $(this).width(newWidth);
            } else {
                $(this).width('');
            }
        }
        this.tables.each(tableWidth);
    }
}
export default new Tables();
