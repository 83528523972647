const $ = jQuery.noConflict();

class Tabs {
    constructor(wrapper, tabsLink, tabsControls) {
        this.tabsWrapper = $(wrapper);
        this.tabsLink = $(tabsLink);
        this.tabsControls = this.tabsWrapper.find(tabsControls);
    }
    init() {
        this.tabsLink.on('click', this.toggleTab);
        this.tabsControls.on('click', this.changeTab);
    }
    toggleTab(e) {
        e.preventDefault();

        const el = $(this);
        const targetId = this.hash;
        const wrapper = el.closest('.block-tabs');

        const target = wrapper.find(`.tabs__tab-content[data-tab-id="${targetId}"]`);

        wrapper.find('.tabs__tab-content').removeClass('active');
        wrapper.find('.tabs__link').removeClass('active');

        el.parent().addClass('active');
        target.addClass('active');
    }

    changeTab() {
        const el = $(this);

        const wrapper = el.closest('.block-tabs');
        const currentTabLink = wrapper.find('.tabs__link.active');
        let nextTabLink;

        if (el.hasClass('tab-prev')) {
            nextTabLink = currentTabLink.prev();
            if (nextTabLink.length === 0) {
                nextTabLink = currentTabLink.parent().children('.tabs__link:last-child');
            }
        } else {
            nextTabLink = currentTabLink.next();
            if (nextTabLink.length === 0) {
                nextTabLink = currentTabLink.parent().children('.tabs__link:first-child');
            }
        }

        const nextTabId = nextTabLink.find('a').attr('href');
        const tabs = wrapper.find('.tabs__tab-content');
        tabs.removeClass('active');
        wrapper.find(`.tabs__tab-content[data-tab-id="${nextTabId}"]`).addClass('active');

        currentTabLink.removeClass('active');
        nextTabLink.addClass('active');
    }
}

export default new Tabs('.tabs__link-list-wrapper', '.tabs__link a', '.tab-nav-button');
